

































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Info from '@/components/common/Info.vue'
import PromotionsTable from '@/components/promotions/PromotionsTable.vue'
import FilterTags from '@/components/common/FilterTags.vue'
import EllipseCommon from '@/components/common/EllipseCommon.vue'
import Drawer from '@/components/common/Drawer.vue'
import AddPromotionForm from '@/components/promotions/AddPromotionForm.vue'
import { PromotionsItemListType } from '@/types/promotions'
import MembersDetail from '@/components/members/MembersDetail.vue'
import { MembersListItemType } from '@/types/members'
import {
  TableExportType,
  TableFilterType,
  TableOrderType,
} from '@/types/general'
import SelectCommon from '@/components/common/SelectCommon.vue'
import PromotionsCard from '@/components/mobile/promotions/PromotionsCard.vue'
import PromotionsFilters from '@/components/mobile/promotions/PromotionsFilters.vue'
import { Message } from 'element-ui'
import EditPromotionForm from '@/components/promotions/EditPromotionForm.vue'
import { promotionsAPI } from '@/api/promotions.api'
import ReasonForm from '@/components/promotions/ReasonForm.vue'
import ReasonBlockForm from '@/components/promotions/ReasonBlockForm.vue'
import { ShopsListItemType } from '@/types/shops'
import ShopInfo from '@/components/shops/ShopInfo.vue'
import PromotionDetailContent from '@/components/promotions/PromotionDetailContent.vue'

@Component({
  components: {
    MembersDetail,
    ReasonBlockForm,
    ReasonForm,
    EditPromotionForm,
    PromotionsFilters,
    PromotionsCard,
    SelectCommon,
    AddPromotionForm,
    EllipseCommon,
    PromotionsTable,
    Info,
    FilterTags,
    Drawer,
    ShopInfo,
    PromotionDetailContent,
  },
})
export default class Promotions extends Vue {
  loading = false
  loadingInfo = false

  page = 1

  addPromotionDialog = false
  showPromotionInfo = false
  editPromotionForm = false
  reasonModal = false
  reasonBlockModal = false
  editPromotionFormLoading = false
  showMemberInfo = false
  showShopInfo = false

  filter = ''
  reasonBlockText = ''
  reasonRevertText = ''
  filters: TableFilterType[] = []

  order: TableOrderType | Record<string, any> = {}

  promotionStatus = {
    NEW: 'Новая',
    ACTIVE: 'Активная',
    MODERATION: 'На модерации',
    FINISHED: 'Завершенная',
    BLOCKED: 'Заблокированная',
    REQUIRED_CHANGE: 'Требует изменений',
  }

  promotionRule = {
    counties: 'Страна',
    regions: 'Регион',
    cities: 'Город',
    shops: 'Магазин',
    shops_by_name: 'Магазин',
    users: 'Участник',
    artnums: 'Артикул',
    masks: 'Код',
    prefixes: 'Префикс',
  }

  statusColor = {
    NEW: 'default',
    ACTIVE: 'success',
    FINISHED: 'gray',
    BLOCKED: 'danger',
    MODERATION: 'warning',
    REQUIRED_CHANGE: 'purple',
  }

  showFilters = false
  showPromotionsFilters = false

  filterOptions = [
    {
      label: 'Все',
      value: '',
    },
    {
      label: 'Активные',
      value: 'ACTIVE',
    },
    {
      label: 'Новые',
      value: 'NEW',
    },
    {
      label: 'На модерации',
      value: 'MODERATION',
    },
    {
      label: 'Требуют изменений',
      value: 'REQUIRED_CHANGE',
    },
    {
      label: 'Завершенные',
      value: 'FINISHED',
    },
    {
      label: 'Заблокированные',
      value: 'BLOCKED',
    },
  ]

  get userId(): string {
    return this.$store.getters['user/user'].id
  }

  get shopsDetail(): ShopsListItemType {
    return this.$store.getters['shops/shopsDetail']
  }

  get promotionsList(): PromotionsItemListType[] {
    return this.$store.getters['promotions/promotionsList']
  }

  get promotionsTotal() {
    return this.$store.getters['promotions/promotionsTotal']
  }

  get exportPromotions(): TableExportType {
    return this.$store.getters['promotions/exportPromotions']
  }

  get promotionsDetail(): PromotionsItemListType {
    return this.$store.getters['promotions/promotionsDetail']
  }

  get userRole(): string {
    return this.$store.getters['user/user'].role
  }

  get isAdmin(): boolean {
    return this.userRole === 'admin'
  }

  get membersDetail(): MembersListItemType {
    return this.$store.getters['members/membersDetail']
  }

  get tableFilters() {
    const params = {}

    this.filters.forEach((data) => {
      params[data.target] = data.data
    })

    if (this.filter) {
      params['UF_STATUS'] = this.filter
    }

    return params
  }

  get tableOrder() {
    const params = {}

    if (this.order && this.order.order) {
      params[this.order.prop] =
        this.order.order === 'descending' ? 'DESC' : 'ASC'
    }

    return params
  }

  get showedPromotionsCount() {
    if (this.promotionsTotal) {
      return 10 * this.page > parseInt(this.promotionsTotal)
        ? this.promotionsTotal
        : 10 * this.page
    } else {
      return 0
    }
  }

  get promotionsTypeCount() {
    const response = this.$store.getters['promotions/promotionsCount']

    const result = {
      NEW: 0,
      ACTIVE: 0,
      MODERATION: 0,
      FINISHED: 0,
      BLOCKED: 0,
      REQUIRED_CHANGE: 0,
    }

    response.forEach((value) => {
      result[value.status] = value.count
    })

    return result
  }

  get activitiesRules(): string[] {
    return this.$store.getters['promotions/activitiesRules']
  }

  get isBlockEnabled(): boolean {
    if (this.isAdmin || this.promotionsDetail.UF_USER === this.userId) {
      return this.promotionsDetail.UF_STATUS === 'ACTIVE'
    }

    return false
  }

  get isEditEnabled(): boolean {
    if (this.isAdmin || this.promotionsDetail.UF_USER === this.userId) {
      return (
        (this.promotionsDetail.UF_STATUS === 'NEW' ||
          this.promotionsDetail.UF_STATUS === 'REQUIRED_CHANGE') &&
        !this.promotionsDetail.EDIT_DISABLED
      )
    }

    return false
  }

  get isModerationEnabled(): boolean {
    if (this.isAdmin || this.promotionsDetail.UF_USER === this.userId) {
      return (
        this.promotionsDetail.UF_STATUS === 'NEW' ||
        this.promotionsDetail.UF_STATUS === 'REQUIRED_CHANGE'
      )
    }

    return false
  }

  get isActivateEnabled(): boolean {
    if (this.isAdmin) {
      return this.promotionsDetail.UF_STATUS === 'MODERATION'
    }

    return false
  }

  get isRequireChangesEnabled(): boolean {
    if (this.isAdmin) {
      return this.promotionsDetail.UF_STATUS === 'MODERATION'
    }

    return false
  }

  async handleMemberInfoShow(value: string) {
    this.showPromotionInfo = false

    this.showMemberInfo = true

    await this.$store.dispatch('members/getMembersDetail', value)

    this.loadingInfo = false
  }

  async handleShopInfoShow(value: string) {
    this.showPromotionInfo = false

    await this.$store.dispatch('shops/getShopsDetailByRemoteId', value)

    this.showShopInfo = true
  }

  handleCloseShopInfo(): void {
    this.showShopInfo = false
  }

  handleReasonPromotionFormShow(): void {
    this.reasonModal = !this.reasonModal
  }

  handleReasonBlockPromotionFormToggle(): void {
    this.reasonBlockModal = !this.reasonBlockModal
  }

  async handleEditPromotionFormShow(): Promise<void> {
    this.editPromotionForm = !this.editPromotionForm
  }

  handleOpenAddPromotionForm(): void {
    this.addPromotionDialog = true
  }

  async handleAddPromotion(): Promise<void> {
    this.addPromotionDialog = false

    this.page = 1

    await this.$store.dispatch('promotions/getPromotionsCount')
    await this.$store.dispatch('promotions/getPromotionsList')
  }

  handleEditPromotion(): void {
    this.editPromotionForm = false

    this.handleChangePage()
  }

  handleDeleteFilter(index: number): void {
    this.filters.splice(index, 1)
  }

  handleReasonBlockSet(reason: string): void {
    this.reasonBlockText = reason

    this.handlePromotionBlock()

    this.handleReasonBlockPromotionFormToggle()
  }

  handleReasonRevertSet(reason: string): void {
    this.reasonRevertText = reason

    this.handlePromotionRevert()

    this.handleReasonPromotionFormShow()
  }

  async setPromotion(): Promise<void> {
    this.loading = true

    await this.$store.dispatch('promotions/getPromotionsList')
    await this.$store.dispatch('promotions/getPromotionsCount')
    await this.$store.dispatch('promotions/getActivitiesRules')

    this.loading = false
  }

  async handleShowPromotionInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showPromotionInfo = true
    this.editPromotionFormLoading = true

    await this.$store.dispatch('promotions/getPromotionsDetail', id)

    this.editPromotionFormLoading = false
    this.loadingInfo = false
  }

  async handleActivatePromotion(): Promise<void> {
    const [error] = await promotionsAPI.setActivate(this.promotionsDetail.id)

    if (!error) {
      this.page = 1
      await this.handleChangePage()

      await this.$store.dispatch('notifications/getNotificationsCount')

      this.showPromotionInfo = false
    }
  }

  async handleSetModeration(): Promise<void> {
    const [error, _] = await promotionsAPI.setOnModerationStatus(
      this.promotionsDetail.id
    )

    if (!error) {
      await this.handleChangePage()

      await this.$store.dispatch('notifications/getNotificationsCount')

      this.showPromotionInfo = false
    }
  }

  async handlePromotionRevert(): Promise<void> {
    const [error] = await promotionsAPI.setNew({
      id: this.promotionsDetail.id,
      text: this.reasonRevertText,
    })

    if (!error) {
      await this.handleChangePage()

      await this.$store.dispatch('notifications/getNotificationsCount')

      this.showPromotionInfo = false
    }
  }

  async handlePromotionBlock(): Promise<void> {
    const [error] = await promotionsAPI.setBlock({
      id: this.promotionsDetail.id,
      text: this.reasonBlockText,
    })

    if (!error) {
      await this.handleChangePage()

      await this.$store.dispatch('notifications/getNotificationsCount')

      this.showPromotionInfo = false
    }
  }

  async handleChangePage(): Promise<void> {
    const tabs = document.querySelector('.promotions__filter') as HTMLElement

    if (tabs) {
      tabs.scrollIntoView({ behavior: 'smooth' })
    }

    const params = {
      filter: {},
      order: {},
      offset: 0,
    }
    params.filter = this.tableFilters
    params.order = this.tableOrder
    params.offset = (this.page - 1) * 10

    this.loading = true

    await this.$store.dispatch('promotions/getPromotionsCount')
    await this.$store.dispatch('promotions/getPromotionsList', params)

    this.showPromotionInfo = false
    this.loading = false
  }

  handleChangeSort(data: TableOrderType): void {
    this.order = data

    this.handleChangePage()
  }

  lazyDownload(): void {
    const link = document.createElement('a')

    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + this.exportPromotions.link
    )
    link.setAttribute('download', this.exportPromotions.name)
    link.click()
    link.remove()
  }

  async handleExportPromotions() {
    await this.$store.dispatch('promotions/getExportPromotions', {
      filter: this.tableFilters,
      order: this.tableOrder,
    })

    if (
      this.exportPromotions.type === 'error' ||
      this.exportPromotions.type === 'queue'
    ) {
      Message.error(this.exportPromotions.text || 'Ошибка')
    } else {
      this.lazyDownload()
    }
  }

  setBonus(bonus: string): string {
    const result = JSON.parse(bonus)

    if (result.type === 'percent') {
      return `${result.number}%`
    } else {
      return `+ ${result.number}`
    }
  }

  setImage(image: string): object {
    let imgArray = JSON.parse(image)

    imgArray.forEach((img, index) => {
      imgArray[index] = process.env.VUE_APP_BASE_URI + img
    })

    return imgArray
  }

  handleClosePromotionInfo(): void {
    if (this.showPromotionInfo) return
    this.$router.push({
      name: 'Promotions',
      query: { ...this.$route.query, promoId: undefined },
    })
  }

  handleShowFilters(): void {
    this.showFilters = !this.showFilters
  }

  handleShowPromotionsFilters(): void {
    this.showPromotionsFilters = !this.showPromotionsFilters
  }

  handleSelectFilter(option: string): void {
    this.filter = option
    this.handleShowFilters()
  }

  handleSelectFilters(data: TableFilterType[]): void {
    this.handleShowPromotionsFilters()
  }

  @Watch('page')
  onPageChange(): void {
    this.handleChangePage()
  }

  @Watch('filters')
  async onFiltersChange(): Promise<void> {
    this.loading = true

    await this.$store.dispatch('promotions/getPromotionsList', {
      filter: this.tableFilters,
    })

    this.loading = false
  }

  @Watch('filter')
  async onFilterChange(): Promise<void> {
    this.loading = true

    await this.$store.dispatch('promotions/getPromotionsList', {
      filter: this.tableFilters,
    })

    this.loading = false
  }

  async mounted(): Promise<void> {
    if (this.$route?.query?.promoId) {
      this.handleShowPromotionInfo(+this.$route.query.promoId)
    }
    await this.setPromotion()
  }
}
